import { Card, InputLabel } from '@common-components';
import { EmailIntegrationType } from 'enums';
import { messages } from 'i18n';
import { Organization } from 'types';
import CardSelect from 'admin/components/CardSelect';
import { EmailIntegrationTypeMap } from 'admin/types';

const { emailIntegrationTitle } = messages.admin.userOrganizationConfiguration;
const { title, warning, confirmLabel, confirmDisableMessage } = messages.admin.disableEmailIntegrationDialog;

interface EmailIntegrationSettingsProps {
  organization: Organization;
  setField: (fieldName: string, value: string | object) => void;
}

export default function EmailIntegrationSettings({ organization, setField }: EmailIntegrationSettingsProps) {
  const setEmailIntegrationField = async (fieldName: string, value: string | EmailIntegrationType) => {
    setField('emailIntegration', { ...organization.emailIntegration, [fieldName]: value === '' ? null : value });
  };

  return (
    <Card sx={{ p: 1 }}>
      <InputLabel sx={{ mb: 1, fontSize: 12 }}>{emailIntegrationTitle}</InputLabel>
      <CardSelect
        onChange={(value) => setEmailIntegrationField('type', value)}
        label="Email Integration Type"
        items={{
          '': 'None',
          SendOnly: EmailIntegrationTypeMap.SendOnly,
          SendAndRead: EmailIntegrationTypeMap.SendAndRead,
        }}
        value={organization.emailIntegration?.type ?? ''}
        confirmationDialog={{
          title,
          warning,
          confirmLabel,
          confirmDisableMessage,
          confirmationText: organization.name,
          confirmOnValue: '',
        }}
      />
    </Card>
  );
}
