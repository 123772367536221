import { omit } from 'lodash';
import { useState } from 'react';
import { Button, Divider, Paper, Stack, Toolbar, Typography } from '@common-components';
import { UploadFile as UploadFileIcon } from '@icons';
import { OrganizationType } from 'enums';
import { getOrganizationType } from 'enums/type-mappers/organization-type-config';
import { useSearchOrganization } from 'hooks';
import { messages } from 'i18n';
import { Organization } from 'types';
import CreateButton from 'components/CreateButton';
import DataGridList, { DEFAULT_PAGE_SIZE } from 'components/DataGridList';
import MarketsBulkUploadDialog from 'admin/components/MarketsBulkUploadDialog';
import OrganizationLink from 'admin/components/OrganizationLink';
import SearchField from 'admin/components/SearchField';
import SelectField from 'admin/components/SelectField';
import OrganizationCreateDialog from 'admin/dialogs/OrganizationCreateDialog';
import { EmailIntegrationTypeMap } from 'admin/types';

export default function OrganizationListPage() {
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const [showMarketsUpload, setShowMarketsUpload] = useState<boolean>(false);
  const [query, setQuery] = useState<string>();
  const [type, setType] = useState<string | null>(null);
  const [offset, setOffset] = useState<number>(0);

  const pageSize = DEFAULT_PAGE_SIZE;

  const {
    items: organizations,
    count,
    isInitialLoading,
  } = useSearchOrganization({
    filter: {
      offset,
      limit: pageSize,
      q: query,
      type: type as OrganizationType,
    },
  });

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      renderCell: ({ row }: { row: unknown }) =>
        OrganizationLink({
          organization: row as Organization,
        }),
      flex: 0.1,
    },
    {
      field: 'type',
      headerName: 'Type',
      renderCell: ({ row }: { row: Organization }) => getOrganizationType(row.type),
      flex: 0.1,
    },
    {
      field: 'emailIntegration as type',
      headerName: 'Email Integration Type',
      renderCell: ({ row }: { row: Organization }) =>
        row.emailIntegration?.type ? EmailIntegrationTypeMap[row.emailIntegration.type] : 'None',
      flex: 0.1,
    },
  ];

  const unmappedChoices = omit(OrganizationType, 'Capitola');
  const choices = Object.fromEntries(
    Object.keys(unmappedChoices).map((value) => [value, getOrganizationType(value as OrganizationType)]),
  );

  return (
    <Paper sx={{ mt: 1 }}>
      <Toolbar sx={{ p: 1 }}>
        <Stack direction="row" justifyContent="space-between" flex={1}>
          <Stack direction="row" alignItems="center">
            <Typography variant="h6" id="tableTitle" component="div" sx={{ mr: 2 }}>
              {messages.admin.organizationList.title}
            </Typography>
            <Divider orientation="vertical" flexItem variant="middle" />
            <SearchField onChange={setQuery} />
            <SelectField choices={choices} label="Type" value={type} onChange={setType} />
            <CreateButton onClick={() => setShowCreateDialog(true)} />
          </Stack>
          <Button
            startIcon={<UploadFileIcon />}
            onClick={() => setShowMarketsUpload(true)}
            color="secondary"
            variant="text"
          >
            {messages.admin.organizationList.uploadMarkets}
          </Button>
        </Stack>
      </Toolbar>
      <DataGridList
        columns={columns}
        items={organizations}
        count={count}
        setOffset={setOffset}
        isLoading={isInitialLoading}
        pageSize={pageSize}
      />
      <OrganizationCreateDialog open={showCreateDialog} onClose={() => setShowCreateDialog(false)} />
      <MarketsBulkUploadDialog isOpen={showMarketsUpload} onClose={() => setShowMarketsUpload(false)} />
    </Paper>
  );
}
